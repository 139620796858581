@import '../../../../../styles/main';

.fh-Zendesk {

  .fh-ZendeskMemberHippaWarning {
    margin: 0 0 0 10px;
    
    &_title {
      margin: .67em 0 1em;
    }
    
    &_info {
      margin: 0 0 2em;
    }
    
    &_action {
      color: $zd-grey-text;
      margin: .67em 0 2em;
    }
    
    .BasicButton.u_linkButton {
      margin: 0 0 0 20px;
    }

    .fh-Zendesk_placeholder {
      margin-top: 50px;
    }
  }
}
