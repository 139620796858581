@import '../../styles/main';

.TrestleLogin {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 40vh 60vh;
  min-height: 100vh;
}

.TrestleLogin_header {
  margin: 30px 0 0 30px;
}

.TrestleLogin_header .Logo_flumeHealth {
  width: 180px;
}

.TrestleLogin_action {
  align-self: end;
  justify-self: center;
}

.TrestleLogin_action .Button {
  margin: 0;
}

// stylelint-disable-next-line plugin/selector-bem-pattern
.TrestleLogin_action .Button_button {
  background-color: $black;

  &:hover {
    background-color: $fh-blue-light;
  }
}

.TrestleLogin_ambiance {
  align-self: center;
  justify-self: right;
  margin: 0 50px 0 0;
}

// stylelint-disable-next-line plugin/selector-bem-pattern
.TrestleLogin_ambiance .Blob___orange {
  width: 200px;
}

.TrestleLogin_trestleLogo {
  align-self: end;
  grid-column: 1 / 4;
  margin: 0 0 50px 50px;
}

// stylelint-disable-next-line plugin/selector-bem-pattern
.TrestleLogin_trestleLogo .Logo_trestle {
  width: 38vw;
}
