@import '../../../../../styles/main';

.fh-MemberCoverage {
  margin: 0 0 30px;
  
  &_title {
    @include zdSecondaryHeaderText();
    font-size: 14px;
    margin: 0 0 8px;
  }
}
