.flumeTrestle {

  .AppAuth {
    min-height: 80vh;
  }
  
  .ThinkingDot + .PaginationByToken {
    margin-top: 88vh;
  }
}
