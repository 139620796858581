@import '../../../../styles/main';

.flumeTrestle {

  .MemberHeader {
    align-items: self-end;
    display: grid;
    grid-template-columns: max-content 1fr;
    margin: 0 0 35px;
    
    &_name {
      margin: 0 50px 0 0;
    }

    &_title {
      @include bodyText(700, 16px);
      margin: 0 0 20px;
    }
    
    &_details {
      display: flex;
    }

    &_element {
      @include bodyText();
      display: block;
      margin: 0 30px 0 0;
    }

    &_label {
      color: $grey7;
      display: block;
    }
  }
}
