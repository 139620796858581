@import '../../../../../styles/main';

.fh-Zendesk_memberInfo {
  margin: 15px 0 30px;

  // keep styling isolated, will likely promote to component
  .fh-ZendeskMemberAccumulator {
    margin: 0 0 40px;
    
    & + .fh-ZendeskMemberAccumulator {
      border-top: 1px solid $zd-grey-border-med;
      padding: 30px 0 0;
    }

    .fh-ZendeskAspect {
      display: table-row;
    }
    
    .fh-ZendeskAspect_label {
      color: $black;
      display: table-cell;
      padding: 0 5px 5px 0;
    }

    .fh-ZendeskAspect_value {
      display: table-cell;
      padding-left: 20px;
      text-align: right;
    }
    
    &_header {
      margin: 0 0 25px;
      position: relative;
    }
    
    &_title {
      font-size: 20px;
      margin: 0;
    }
    
    &_anniversary {
      font-size: 13px;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
    }
    
    &_anniversaryDate,
    &_anniversaryLabel {
      margin: 0;
    }

    &_totals {
      border-collapse: separate;
      border-spacing: 0 7px;
      display: table;
      margin: 0 0 20px;
    }
  
    &_table {
      column-gap: 15px;
      display: grid;
      font-size: 13px;
      grid-template-columns: max-content 1fr 1fr;
      justify-items: right;
      margin: 10px 0 0;
      row-gap: 5px;
    }

    &_tableHeader {
      align-self: center;
      line-height: 1.1;
      text-align: right;
    }
 
    &_tableRowLabel {
      justify-self: left;
    }
  }

  &_placeholder {
    @include zdPlaceholder();
  }
}
