@import '../../../styles/main';

.fh-ZendeskLoginPage {
  align-content: center;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;

  &_header {
    margin: 30px 0 0 30px;

    .Logo_flumeHealth {
      width: 180px;
    }
  }

  &_action {
    align-self: end;
    justify-self: center;
    text-align: center;

    .Button {
      margin: 0;
    }
  }

  &_actionMessage {
    color: $grey3;
    font-size: 13px;
    text-align: center;
  }

  &_ambiance {
    align-self: center;
    justify-self: right;
    margin: 0 50px 0 0;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    .Blob___orange {
      width: 200px;
    }
  }

  &_trestleLogo {
    align-self: end;
    grid-column: 1 / 4;
    margin: 0 0 50px 50px;

    .Logo_trestle {
      width: 38vw;
    }
  }
}
