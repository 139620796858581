@import '../../../styles/main';

// common Zendesk styles for all Trestle-based apps

// Trestle embeds for Zendesk
// `fh-Zendesk` namespace for Flume's Trestle app in Zendesk
.fh-Zendesk {
  
  // overrides
  &-minimal {
    
    .Input_input {
      border: none !important;
      border-bottom: 1px solid $black !important;
      border-radius: 0 !important;
      padding-left: 0 !important;
    }
  }

  // reset padding for image
  .SearchIconButton {

    .Button_button {
      padding: 0;
    }
  }

  // component overrides for Zendesk

  // Zendesk style blue outline button
  // stylelint-disable-next-line no-descending-specificity
  .Button_button {
    background-color: transparent;
    border: 1px solid $zd-blue;
    border-radius: 4px;
    color: $zd-blue;
    font: 13px / 34px $zd-stack;
    letter-spacing: normal;
    padding: 0 15px;
    text-transform: none;

    &:hover {
      background-color: $zd-blue-background;
      border-color: $zd-blue-dark;
      border-radius: 4px;
      color: $zd-blue-dark;
    }

    &:not([disabled]):hover {
      background-color: $zd-blue-background;
      border-color: $zd-blue-dark;
      border-radius: 4px;
      color: $zd-blue-dark;
    }
  }

  .Button {

    &.u_fullWidth {
      text-align: right;
      width: 100%;
    }
    
    &.u_linkButton {
      margin: 0;
      
      .Button_button {
        background-color: transparent;
        border: none;
        line-height: 13px;
        padding: 0;
      }
    }
    
    &.fh-Zendesk_primaryButton {

      .Button_button {
        @include zdPrimaryButton();
      }
    }

    &.fh-Zendesk_primaryButtonAlt {

      .Button_button {
        @include zdPrimaryButtonAlt();
      }
    }

    &.fh-Zendesk_secondaryButton {

      .Button_button {
        @include zdSecondaryButton();
      }
    }

    &.SubmitButton {

      .Button_button {
        background-color: $zd-blue;
        border-color: $zd-blue;
        color: $white;

        // stylelint-disable-next-line selector-max-specificity
        &:hover {
          background-color: $zd-blue-dark;
          border-color: $zd-blue-dark;
        }
      }
    }
  }
  
  .Button_button___disabled:hover {
    background-color: transparent;
  }

  .Chip {
    background-color: #F8F9F9;
    border: 1px solid #C2C8CC;
    border-radius: 3px;
    color: #2F3941;
    display: inline-block;
    font-size: 11px;
    line-height: 13px;
    margin: 0 3px 3px 0;
    padding: 3px 17px 4px 6px;
    position: relative;

    &_button {
      color: #87929D;
      cursor: pointer;
      font-size: 12px;
      padding: 3px;
      padding-right: 2px;
      position: absolute;
      right: 1px;
      top: 0;
      user-select: none;

      &::after,
      &::before {
        background-color: #87929D;
        height: 10px;
        top: 11px;
        width: 1px;
      }
    }
  }

  .ComposableForm {
    column-gap: 0;
    grid-column-gap: 0;
    grid-template-columns: 1fr;
    margin: 0;
    width: 100%;
  }

  .Dropdown {
    margin: 0 0 20px;

    &_select {
      background-size: 8px auto, 100%;
      border: 1px solid $zd-grey-border-med;
      border-radius: 4px;
      font: 400 14px / 1.3 $zd-stack;
      padding: 5px 26px 5px 11px;
    }
  }

  .FormError {
    background-color: $zd-grey-background;
    border: 1px solid $zd-red-error;
    border-radius: 4px;
    color: $zd-red-error;
    font: 700 14px / 1.3 $zd-stack;
    padding: 10px 20px 12px;
    text-align: center;
  }

  .Input {
    min-height: initial;
    width: 100%;

    .Input_input {
      appearance: none;
      background-color: $white;
      border: 1px solid $zd-grey-border-med;
      border-radius: 4px;
      color: $zd-black-text;
      font-family: inherit;
      font-size: 14px;
      height: initial;
      line-height: 1.28571;
      padding: .428571em .857143em;
      transition: border-color .25s ease-in-out 0s, box-shadow .1s ease-in-out 0s, background-color .25s ease-in-out 0s, color .25s ease-in-out 0s;
      vertical-align: middle;
      width: 100%;
    }
  }

  .Label {
    color: $zd-black-text;
    font: 700 13px / 1 $zd-stack;
    letter-spacing: initial;
    margin: 0 0 10px;
    text-transform: none;
  }

  .OptionalText {
    display: none;
  }

  .ProcessMarker {
    font-size: 18px;
    line-height: 32px;

    &_item {
      height: 32px;
      margin: 10px 0;
      width: 32px;
    }

    &___complete &_item {
      background-color: $zd-black-text-80;
      border: 2px solid $zd-black-text-80;
    }
  }

  .PaginationByToken {

    // stylelint-disable-next-line no-descending-specificity
    .Button_button {
      background-color: transparent;
      border: none;

      // stylelint-disable-next-line no-descending-specificity
      &:hover {
        text-decoration: underline;
      }

      // stylelint-disable-next-line selector-max-specificity
      &:not([disabled]):hover {
        background-color: transparent;
        border-color: transparent;
        color: $zd-blue-dark;
      }
    }

    .Dropdown {
      margin: 0;
      max-width: none;
    }

    .PaginationByToken___currentPage {

      // stylelint-disable-next-line
      .Button_button {
        align-items: center;
        border: none;
        display: flex;
        justify-content: center;

        // stylelint-disable-next-line
        &:hover {
          background-color: transparent;
          text-decoration: none;
        }

        // stylelint-disable-next-line selector-max-specificity
        &:not([disabled]):hover {
          background-color: transparent;
          border-color: transparent;
          color: $zd-blue-dark;
        }

        // stylelint-disable-next-line
        &::after {
          align-items: center;
          border: 1px solid $zd-blue;
          border-radius: 50%;
          content: '';
          display: flex;
          height: 28px;
          justify-content: center;
          position: absolute;
          width: 28px;
          z-index: -1;
        }
      }
    }
  }

  .Radio_button {
    border: 1px solid $black;
    height: 18px;
    width: 18px;

    &:after {
      background-color: $black;
      border: none;
      height: 10px;
      left: 4px;
      top: 4px;
      width: 10px;
    }
  }

  .Radio_label {
    font: 13px / 1 $zd-stack;
    margin: 3px 0 0 7px;
  }

  .Radios {
    display: inline-block;
    margin: 5px 0 15px 3px;

    .Radio {
      display: inline-block;
      margin: 0 19px 13px 0;
    }
  }

  .Searchable {

    &_chips {
      margin: 0 0 6px;
      width: 100%;
    }

    &_option {
      border-bottom: 1px solid $zd-grey-border;
      padding: 7px 0;
    }

    &_options {
      background-color: $white;
      border-left: 1px solid $zd-grey-border;
      border-top: 1px solid $zd-grey-border;
      box-shadow: 5px 6px $zd-grey-border-med;
      color: $zd-black-text;
      font: 13px/1 $zd-stack;
      left: -50px;
      width: 290px;
    }
  }

  .Suggestable {
    margin: 0 0 20px;
    width: 100%;

    &_options {
      @include zdOverlay();
      color: $zd-grey-text;
      font: 14px / 1.3 $zd-stack;
      padding: 5px 0 0;
      width: 280px;
    }

    &_option {
      border-bottom: none;
      padding: 10px 0 10px 10px;

      &:hover {
        background-color: $zd-grey-border-light;
        box-shadow: inset 0 3px 0 $zd-overlay-shadow, inset 0 -3px 0 $zd-overlay-shadow;
      }
    }
  }

  .Tabs {
    background-color: $zd-grey-background;
    border-bottom: 1px solid $zd-grey-border;
    margin: 0 0 12px;
    padding: 5px 0 0 10px;

    &_button {
      color: $zd-blue;

      &:hover {
        color: $zd-blue-dark;
      }
    }

    &_button___selected {
      background-color: $white;
      border: 1px solid #E9EBED;
      border-bottom: 1px solid $white;
      color: $black;
      cursor: default;
      position: relative;
      top: 1px;

      &:hover {
        color: $black;
      }
    }
  }

  .TopLabel {
    font: 15px / 1 $zd-stack;
    letter-spacing: initial;
    margin: 0 0 10px;
    text-transform: none;
  }

  .u_errorMsg {
    color: $zd-red-error;
    font: 13px / 1.2 $zd-stack;
  }

  //////////////////////////////////////////////
  
  // shared Zendesk styles
  
  & &_altActionButton {
    border-radius: 4px;
    font-size: 11px;
    opacity: .7;
    padding: 2px 3px;
    text-transform: lowercase;
  }

  & &_errorMessage {
    color: $zd-red-error;
    font: 15px / 1 $zd-stack;
    position: absolute;
    top: -40px;
  }

  & &_headerBlock {
    position: relative;
  }

  // override shared Zendesk Label style
  & &_processSection {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 20px 30px 0;

    .fh-ZendeskLinkButtonList {
      margin: 10px 0 5px;
    }

    .Label {
      line-height: 17px;
      margin: 0 0 2px;
    }
  }

  // generally preview step number and step title
  & &_processSectionPreview {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 30px;
    opacity: .4;
    pointer-events: none;
  }

  a,
  &_link,
  &_linkPlaceholder {
    color: $zd-blue;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &_placeholder {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100%;
  }

  &_optionsGroup {
    display: flex;
    flex-wrap: wrap;

    .Button {
      margin: 0 10px 15px 0;
    }
  }

  &_sectionText,
  &_sectionHeaderText {
    color: $zd-black-text;
    font-size: 15px;
    margin: 0 0 10px 53px;
  }

  &_sectionHeaderText {
    margin: 0 0 0 15px;
    max-width: 240px;
  }

  &_sidebarSection,
  &_sidebarSectionActions {
    margin: 20px 0 10px 53px;
    width: 100%;
  }

  &_sidebarSectionActions {
    display: flex;

    .BasicButton {
      margin: 0;
    }

    .fh-ZendeskLinkButton {
      font-size: 15px;
      margin: 0 30px;
      padding: 10px 10px;
    }
  }

  & &_supportTicketSidebar {

    // member selector
    & > .Dropdown {
      display: inline-block;
      margin: 10px 0 12px;
    }

    // stylelint-disable-next-line no-descending-specificity, selector-max-specificity
    & > .Dropdown + .BasicButton .Button_button {
      background: transparent;
      border: none;

      // stylelint-disable-next-line
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_sidebarTitle {
    color: $zd-black-text;
    font-size: 15px;
    margin: 5px 0 10px;
    
    .fh-ZendeskLinkButton {
      font-size: 12px;
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  &_sidebarText {
    color: $zd-black-text;
    font-size: 14px;
    line-height: 1.3;
    margin: 5px 0 15px;
  }
  
  &_boldText {
    color: $zd-blue-dark;
    font-weight: bold;
  }
  
  &_highlightText {
    background-color: $zd-yellow-extra-light;
    box-decoration-break: clone;
    display: inline;
    font-style: italic;
    line-height: 1.45;
    padding: 3px 5px 3px 3px;
  }
  
  // in-content sections
  &_sidebarText + &_sidebarTitle {
    margin-top: 30px;
  }

  &.fh-Zendesk___development {
    align-items: center;
    background-color: $zd-grey-background;
    display: grid;
    height: 100vh;
    justify-content: center;

    // mimic the general spacial feel of this unit
    .fh-Zendesk_supportTicketSidebar {
      background-color: $white;
      border: 1px solid $zd-grey-border;
      height: max-content;
      padding: 15px 15px 30px;
      width: 320px;
    }
  }
}
