@import '../../../../../styles/main';

.fh-Zendesk {
  
  .fh-ZendeskMemberSearchForm {
    
    .SubsectionTitle {
      color: $zd-black-text;
      font-size: 15px;
      font-weight: bold;
      margin: 0 0 30px;
    }
  }
}
