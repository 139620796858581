.fh-Zendesk {

  .fh-ZendeskMemberSearchResults {
    margin: 30px 0 0;

    .fh-ZendeskMemberSearchResults_result {
      
      .Button {
        margin: 0;
      }

      .Button_button {
        border: none;
        padding: 0 5px;
      }
    }

    .fh-Zendesk_secondaryButton {
      margin: 0;

      .Button_button {
        padding: 0 10px 0 0;
      }
    }
  }
}
