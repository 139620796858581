@import '../../../../styles/main';

.flumeTrestle {

  .MemberSearch {
    border-left: 8px solid $fh-yellow;
    margin: 0 0 40px;
    padding-left: 20px;
    
    &_example {
      @include headerText(700, 14px, 1.4);
      max-width: 540px;
      width: 80%;
    }

    .ComposableForm {
      column-gap: unset;
      display: flex;
      margin: 0;

      .Label,
      .OptionalText {
        display: none;
      }

      .Input {
        margin: 0 30px 0 0;
        max-width: 150px;
        min-height: 70px;
        min-width: 100px;
      }
    }
  }
}
