@import '../../styles/main';

.ComponentsByTypeReferencePage {
  @include listPage();
  
  .Checkboxes,
  .Radios {
    width: initial;
  }
  
  .Button___alternateStyle,
  .Checkboxes___alternateStyle,
  .Dropdown___alternateStyle,
  .Input___alternateStyle,
  .Radios___alternateStyle,
  .Searchable___alternateStyle,
  .SecondaryText___alternateStyle,
  .Suggestable___alternateStyle,
  .Textarea___alternateStyle,
  .Toggle___alternateStyle {
    background-color: $fh-yellow-a22;
  }

  .Button___alternateStyle {

    .Button_button {
      @include button($black, $white, $black);
    }
  }
}
