@import '../../../../styles/main';

.flumeTrestle {
  
  .Breadcrumbs {
    margin: 0 0 25px;
    
    &_button {
      background-color: transparent;
      border: none;
      color: $fh-green;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:first-of-type {
        padding-left: 0;
      }
      
      &:last-of-type {
        color: $black;
        cursor: default;
        text-decoration: none;
      }
    }
    
    &_spacer {
      color: $grey1;
    }
  }
}
