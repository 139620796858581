@import '../../styles/main';

.LinkList {
  color: $black;
  font: 400 13px / 1 $sans-serif-stack;;
  margin: 63px 0 0 0;
}

.LinkList_title {
  border-bottom: 5px solid $fh-yellow;
  font: 700 22px / 1.3 $header-stack;
  margin: 0 0 12px;
  padding: 0 0 3px 5px;
}

.LinkList_link {
  color: $black;
  display: block;
  letter-spacing: .4px;
  margin: 0 0 10px;
  padding: 0 0 3px 5px;
  text-align: left;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
  
  &:visited {
    color: $black;
  }
}
