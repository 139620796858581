.flumeTrestle {
  
  .MemberDemographics {

    &_details {
      column-gap: 55px;
      display: grid;
      grid-template-columns: max-content 1fr;
    }
  }
}
