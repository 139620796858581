@import '../../../../styles/main';

.fh-Zendesk {

  .fh-ZendeskLinkedItemGroup {
    border-top: 1px solid $zd-grey-border-light;
    margin: 0 0 30px;
    padding: 20px 0 0;

    &_title {
      @include zdSecondaryHeaderText();
      margin: 0 0 25px;
    }

    &_searchMembers {
      margin: 25px 0 0;
    }
    
    &_noResults {
      color: $zd-grey-text; // $zd-black-text;
      font-size: 13px;
      margin: 0 0 5px;
    }

    .fh-ZendeskLinkedItem_relationship {
      color: black;
      display: block;
      line-height: 1.4;
      margin: 0 0 2px;
      opacity: .5;
      padding: 10px 0 0;
    }

    .fh-ZendeskLinkedItem_type {
      line-height: 1.4;
      padding: 0 2px 0 1px;
    }

    .fh-ZendeskLinkedItem_info {
      display: block;
      line-height: 1.4;
      padding: 0 0 10px;

      // stylelint-disable-next-line selector-max-specificity
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
