.SpinnerScaleout {
  -webkit-animation: scaleout 1s infinite ease-in-out;
  animation: scaleout 1s infinite ease-in-out;
  background-color: #333;
  border-radius: 50%;
  height: 60px;
  margin: 0;
  width: 60px;
}

@keyframes scaleout {

  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}
