// stylelint-disable scss/partial-no-import

// mixin utils

@import 'colors';
@import 'text';

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

// mobile first media queries
// naming based on media and (optionally) size
// sizes are numeric, from 1-n, from smallest to largest
// determine breakpoints based on design needs
// not generic device type

@mixin screen2 {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin screen3 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin screen5 {
  @media screen and (min-width: 920px) {
    @content;
  }
}

@mixin screen6 {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

// form placeholders

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

// example with optional override styles
// @include button($black, $fh-red-salmon);

@mixin button($color: $white-a94, $background-color: $fh-green, $border-color: none) {
  background-color: $background-color;
  border-radius: 90px;
  color: $color;
  font: 700 14px / 1 $sans-serif-stack;
  height: initial;
  letter-spacing: 1.1px;
  padding: 16px 24px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;

  @if $border-color == none {
    border: none;
    // stylelint-disable-next-line block-closing-brace-newline-after, at-rule-empty-line-before
  } @else {
    border: 1px solid $border-color;
  }
}

@mixin hamburger($color: $white-a94, $background-color: transparent, $width: 15px) {
  background-color: $background-color;
  border: none;
  border-bottom: 2px solid $color;
  color: $color;
  cursor: pointer;
  height: 16px;
  position: relative;
  width: $width;

  &::before,
  &::after {
    background-color: $color;
    content: ' ';
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: $width;
  }

  &::after {
    top: 7px;
  }
}

@mixin iconDelete {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAUElEQVR4Ae3PNxHAMBQE0eUhgxWfj02lapmAcw6717+Z49Qs00aWN3N7ybZxgm/rqLuCgoKCgoJ1FVeZLVaBwWwdZTFXSLCEDOqCs0HihKwHMdrCLaZrR90AAAAASUVORK5CYII=');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

@mixin iconEdit {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAAsCAYAAAA0NKuuAAAAAXNSR0IArs4c6QAAAu1JREFUaAXtmLtrFFEUxhODiASRRURSGFJYiCAWgghBwqaQNKYQAmlsFEHBB4hWYmthpSKCgklhFfQfsDGIgkhARCQWPkBBYnyt4DO+vx/Mgcu6yxyFzb0zzIGPeX1n9/vunTln7nR1VVGuEegpkJ1eaT0oDAoPha9C6WJYjhaE3xl+aTstrBBKExvk5JuAScx+zvY5/iLUhcLHKjn4IGCKLcfEPoFbl/M/hUNCYYP68ULADDPKzIbRp4M5gevcyheFQsZdqbYZG2njgMG4k/Ewe6QNL9nTVwPxBxwqb2f8Zw5uMpSTUsLsgHNOVY/FY/ZnnfzotN1SgEFEX3Oqmcz45G135kSl0SupoP8yM3vEt4G5EFW988/DXjmvnGWOvM3i/BAYmHsOfnQKbeKTgOCPwmohL1aKYP31rfY9A5P3mx29vly//kbA5IKwXsiLbhGoruTQX9cJSQeCnwoI5hbcJnhiWiRyeDZ3eBJic2YkwASPO8WcCXKOO3Oi0qYCwcecSvaKZxX2ijMnKo2ZMMHnnUqGxLPWc9+ZE5VWDwRfdyrpF89WKq+0v9SZF41GS7C1JKsSilFeUJXfCTzL5NKKko8HUohg2ohX8JMsh6q8RUg+TkmhVdgxp9obQY63Kjt/ujM0XtWskFBtPTEhkg3MCU9CbA7PIa9oiH7pFBNW5cvOnOi0S1KASWa0+VNIK3G7dNJaz81WhFTP8ZUOo2cdAofEYUDgP3Lwk6HwbCKaGcpbXWwUxz5pvnbwRUknTksKRudzJK3VdeuvLL3W5PCTu8wzhlHaRLtg7flegMfbT/JLLmn8K+Z0BgPMbKvg+46Z/K79QrwQtDLCGxBGdzZd3K/j54JVV0zWmziFOewJjAxo/7BwSzDzDABGZwWKVmFjq5RjxgzZPltmkOd3k1D4oF3YrcmWqsoXhaMCs12qGJSbUaG3VK4qM4mMQHetVqNAdCwajQYrmOixJLqCRRJQGV2kga7+phqB/xyBP/KRy6YCTRyeAAAAAElFTkSuQmCC');
  background-position: 7px 50%;
  background-repeat: no-repeat;
  background-size: 29px 22px;
}

@mixin elementTextShield {
  background-image: url('data:image/svg+xml,%3Csvg width="40" height="60" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="%23FFF" fill-rule="evenodd"%3E%3Cpath fill="%23FFF" d="M0 0h40v60H0z"/%3E%3Ccircle fill="%23111" cx="20" cy="30" r="10"/%3E%3C/g%3E%3C/svg%3E');
}

@mixin bodyText($weight: 400, $size: 13px, $lineHeight: 1.4, $letterSpacing: null, $fonts: $sans-serif-stack) {
  font: #{$weight} #{$size} / #{$lineHeight} #{$fonts};

  @if $letterSpacing != null {
    letter-spacing: $letterSpacing;
  }
}

@mixin headerText($weight: 700, $size: 30px, $lineHeight: 1.15, $letterSpacing: null, $fonts: $header-stack) {
  font: #{$weight} #{$size} / #{$lineHeight} #{$fonts};

  @if $letterSpacing != null {
    letter-spacing: $letterSpacing;
  }
}

@mixin listPage() {
  display: grid;
  grid-template-columns: 260px 1fr;
  margin: 30px auto 0 30px;
  max-width: calc(100vw - 200px);
}

@mixin boxShadowPrimary() {
  border-bottom: 1px solid $fh-blue-navy-a14;
  border-left: 1px solid $fh-orange-a15;
  border-right: 1px solid $fh-blue-navy-a11;
  border-top: 1px solid $fh-yellow-a22;
  box-shadow: 0 6px 15px 0 $fh-blue-navy-a15;
}

@mixin resultsPage() {
  border-bottom: 2px solid #FFD31B;
  min-height: 50vh;
}

@mixin resultsTable() {
  @include bodyText();
  border-collapse: collapse;
  margin: 0 0 30px;
  table-layout: fixed;
  width: 100%;

  th {
    border-bottom: 1px solid $black;
  }

  tbody {

    & > tr {

      td {
        border-top: 1px solid $grey5;
        padding: 10px 20px 10px 0;
        white-space: nowrap;
      }

      &:first-of-type > td {
        border-top: none;
      }
    }
  }
}
