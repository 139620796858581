@import '../../../../styles/main';

.flumeTrestle {
  
  .AddressVertical {
    
    &_title {
      @include bodyText(700, 16px);
      margin: 0 0 20px;
    }
    
    &_element {
      @include bodyText(400, 13px, 1.1);
      display: grid;
      grid-template-columns: 90px 1fr;
      row-gap: 12px;
    }
    
    &_label {
      color: $grey7;
      display: inline-block;
    }
    
    &_value {
      max-width: 230px;
    }
  }
}
