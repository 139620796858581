@import '../../../../styles/main';

.fh-ZendeskUnauthorized {
  background-color: $zd-black-text;
  border-radius: 4px;
  color: $white;
  margin: 30px 0 0;
  padding: 5px 20px;

  &_title {
    @include zdHeaderText();
  }

  &_message {
    margin: 0 0 30px;
  }
}
