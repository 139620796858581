@import '../../styles/main';

.TopNav {
  background-color: $fh-green;
  color: $white;
  display: grid;
  grid-template-columns: 33px 1fr 1fr;
  height: 70px;
  position: relative;
  z-index: $z-index-top-nav;
}

.TopNav .Logo_flumeTrestle {
  align-self: center;
  justify-self: left;
  margin: 0 0 5px 19px;
}

.TopNav_menuButton {
  @include hamburger();
  align-self: center;
  justify-self: right;
}

.TopNav_user {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: right;
  margin: 0 30px 0 0;
}

.TopNav_userIcon {
  margin: 0 0 4px;
  width: 25px;
}

.TopNav_userName {
  @include bodyText($weight: 700, $size: 14px, $lineHeight: 1);
}
