@import '../../../styles/main';

.fh-ZendeskAuthed {
  margin: 12vh 0 0;
  text-align: center;
  
  &_checkmark {
    height: 90px;
    margin: 0 0 20px;
  }
  
  &_title {
    line-height: 1.4;
    margin: 0;
    text-align: center;
  }
}
