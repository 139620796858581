@import '../../../../styles/main';

.flumeTrestle {
  
  .TabNav {
    background-color: $grey8;
    margin: 0 0 30px;
    position: relative;

    &::after {
      background-color: $white;
      content: ' ';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    
    &_button {
      @include headerText(700, 16px);
      background-color: transparent;
      border: none;
      color: $grey7;
      cursor: pointer;
      padding: 10px 20px 5px;
      
      &:first-of-type {
        margin-left: 5px;
      }
      
      &___selected {
        background-color: $white;
        border-top: 2px solid $white;
        color: $black;
        position: relative;
        
        &::after {
          background-color: $fh-yellow-a50;
          content: ' ';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          top: -3px;
          width: 100%;
        }
      }
    }
  }
}
