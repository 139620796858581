@import '../../styles/main';

.ResultsFooter {
  border-top: 3px solid $fh-yellow;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: right;
  margin: 5px 0 0;
  padding: 7px 0 0;
}
