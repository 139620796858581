@import '../../../../styles/main';

.flumeTrestle {
  
  .MemberClaimsResults {
    @include resultsPage();
    
    &_table {
      @include resultsTable();
    }

    &_incurred,
    &_payment,
    .MemberClaimsResultsItem_incurred,
    .MemberClaimsResultsItem_payment {
      width: 85px;
    }

    &_id,
    .MemberClaimsResultsItem_id {
      width: 135px;
    }

    &_type,
    .MemberClaimsResultsItem_type {
      padding-right: 40px;
      width: 4%;
    }

    &_charge,
    .MemberClaimsResultsItem_charge {
      padding-right: 60px;
      text-align: right;
      width: 14%;
    }

    &_provider,
    .MemberClaimsResultsItem_provider {
      width: 20%;
    }

    &_status,
    .MemberClaimsResultsItem_status {
      width: 35px;
    }
  }
}
  
