.fh-ClaimLookup {

  .ComposableForm.fh-staticUnit {
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: 230px 1fr;
    
    .SearchIconButton {
      justify-self: end;
      margin: 0;

      // stylelint-disable-next-line selector-max-specificity
      .Button_button {
        border: 0;
        border-radius: 50%;
      }

      // stylelint-disable-next-line selector-max-specificity
      .u_errorMsg {
        display: none;
      }
    }
  }
  
  .FormError {
    display: none;
  }
}
