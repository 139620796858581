@import '../../../../styles/main';

.flumeTrestle {
  
  .NoSearchResults {
    @include headerText(700, 18px);
    font-style: italic;
    margin: 130px auto;
    width: max-content;
  }
}
