$componentSideMargin: 30px;
$far-left: -999px;

// rounded corners
$rounded-corner-1: 5px;
$rounded-corner-2: 10px;
$rounded-corner-3: 15px;
$rounded-corner-4: 20px;
$rounded-corner-5: 25px;
$rounded-corner-6: 30px;

// z-index
$z-index-left-nav: 50;
$z-index-top-nav: 60;
$z-index-top-nav-message: 70;
$z-index-modal: 100;
$z-index-modal-content: 110;
$z-index-error: 200;
