@import '../../styles/main';

.AlarmsPage {
  @include listPage();
  
  .ResultsList_headers,
  .ResultsList_item {
    display: grid;
    grid-template-columns: 4% 17% 13% 10% 24% 12% 8% 12%;
  }
  
  .SpinnerScaleout {
    margin: 110px auto;
  }
}
