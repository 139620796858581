.fh-Zendesk {

  .fh-ZendeskMemberSearchInstructions {

    &_formTitle {
      margin: 0 0 5px;

      & + .fh-Zendesk_sidebarText {
        margin: 0 0 25px;
      }
    }
 
    &_results {

      & > .BasicButton {
        margin-top: 30px;
      }
    }

    .fh-Zendesk_sidebarText + .Button {
      margin-top: 20px;
    }
  }
}
