@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/DMSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/DMSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/DMSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Lato-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Lato-Bold.woff2') format('woff2');
}
