.flumeTrestle {
  
  .LeftRail {
    grid-row: 1 / span 4;
  }
  
  .LeftNav {
    padding: 80px 20px 40px 20px;
  }

  .LeftNav_item {
    margin: 0 0 3px;
    transition: background-color .3s ease;
  }
  
  .LeftNav_item.u_iconAlarm {
    background-position: 16px 50%;
  }

  .LeftNav_item.u_iconReport {
    background-position: 20px 50%;
  }
  
  .LeftNav_item.u_iconAlarm,
  .LeftNav_item.u_iconDemo,
  .LeftNav_item.u_iconMembers,
  .LeftNav_item.u_iconReport,
  .LeftNav_item.u_iconNone {
    border-radius: 50px;
    padding: 20px 0 20px 60px;
    
    &:hover {
      background-color: #fff;
    }
  }
}
