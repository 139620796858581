@import '../../../../styles/main';

.flumeTrestle {
  
  .MembersSearchResults {
    @include resultsPage();

    &_table {
      @include resultsTable();
    }

    &_id,
    .MembersSearchResultsItem_id {
      min-width: 95px;
      width: 17%;
    }

    &_name,
    .MembersSearchResultsItem_name {
      width: 23%;
    }

    &_dob,
    .MembersSearchResultsItem_dob {
      min-width: 95px;
      width: 14%;
    }

    &_sponsor,
    .MembersSearchResultsItem_sponsor {
      width: 23%;
    }

    &_platform,
    .MembersSearchResultsItem_platform {
      width: 23%;
    }
  }
}
