@import '../../styles/main';

.ReportListingsPage {
  @include listPage();

  .ResultsList_headers,
  .ResultsList_item {
    display: grid;
    grid-template-columns: 8% 11% 18% 31% 20% 12%;
  }

  .SpinnerScaleout {
    margin: 110px auto;
  }
}
