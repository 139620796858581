@import '../../styles/main';

.ReportsRunPage {
  @include listPage();
  margin-bottom: 30px;
  
  &_placeholder, 
  .ComposableForm {
    border-bottom: 3px solid $fh-yellow;
    padding-bottom: 60px;
  }
  
  .SpinnerScaleout {
    margin: 110px auto;
  }
}
