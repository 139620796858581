@import '../../../../styles/common/main';

.fh-Zendesk {

  .fh-ZendeskMemberClaimRow {
    border-bottom: 1px solid $zd-grey-border-med;
    font-size: 13px;
    line-height: 1.2;
    margin: 0 0 7px;

    &:last-of-type {
      border-bottom: none;
    }

    &_claimSummary {
      align-items: center;
      column-gap: 2%;
      display: grid;
      grid-template-columns: 11% 22% 9% 8.5% 27% 1fr;
      padding: 0 0 8px;
    }

    &_claimCharge {
      text-align: right;
    }

    &_claimStatus {
      text-align: center;
    }

    &_claimActions {
      text-align: right;

      .fh-Zendesk_linkPlaceholder,
      .fh-ZendeskMemberClaimRow_claimActionsLink,
      .Button {
        display: block;
        margin: 0 0 4px;
      }

      .Button_button {
        @include zdSecondaryButton();
        color: $zd-blue;
        line-height: 1.2;
        padding: 0;
      }
    }

    &___open {

      .fh-ZendeskMemberClaimRow_claimSummary {
        color: $grey6;
        transition: color .15s ease-out;
      }
    }

    &_extrasHeader {
      font-size: 14px;
      grid-column: 1 / 6;
      margin: 0 0 15px;
    }

    &_claimDetails,
    &_notes {
      background-color: $zd-yellow-extra-light;
      line-height: 1.35;
      margin: -2px 0 4px;
      padding: 10px 0;
      position: relative;

      &::before,
      &::after {
        background-color: $zd-yellow-extra-light;
        content: '';
        height: 100%;
        left: -15px;
        position: absolute;
        top: 0;
        width: 15px;
      }

      &::after {
        left: unset;
        right: -15px;
      }

      .fh-Zendesk_linkPlaceholder {
        display: block;
        text-align: right;
      }
    }

    &_claimDetails {
      column-gap: 2%;
      display: grid;
      grid-template-columns: 11% 24.5% 17% 25% 1fr;

      // expanded links
      div:last-of-type {
        text-align: right;
      }
    }

    &_claimDetailsHeader {
      color: $grey3;
      display: block;
      margin: 0 0 2px;
    }

    &_claimDetailsInfo {
      display: block;
      margin: 0 0 10px;
    }

    &_claimDetailsLinks {
      position: absolute;
      right: 0;
      top: 8px;
    }

    &_claimDetailsLink {
      display: block;
      margin: 0 0 4px;
    }

    &_noteUser {
      color: $black;
      margin: 0 0 5px;
    }

    &_noteDates {
      color: $grey3;
      margin: 0 0 5px;
    }

    &_noteCreated,
    &_noteUpdated {
      margin: 0 0 0 20px;
    }

    &_noteText {
      margin: 5px 0 25px;
      width: 80%;
    }
  }
}
