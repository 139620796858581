@import '../../styles/main';

.DeleteItem {
  @include bodyText();
  margin: 0 40px;
}

.DeleteItem_header {
  @include headerText();
  margin: 0 0 40px;
}
