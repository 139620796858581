@import '../../../../styles/main';

.fh-ZendeskAspect {
  display: flex;
  font-size: 13px;
  line-height: 1.2;
  margin: 0 0 8px;

  &___groupStart {
    margin-top: 20px;
  }

  &___groupEnd {
    margin-bottom: 20px;
  }

  &___toggleDisplay {
    cursor: pointer;

    .fh-ZendeskAspect_value {
      position: relative;

      &::before {
        @include elementTextShield();
        background-position-y: 3px;
        background-size: 9px 14px;
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
  
  &___ssn {

    .fh-ZendeskAspect_value {
      min-width: 36px;
    }
  }

  &_label {
    color: $grey6;
    min-width: 100px;
    padding: 0 7px 0 0;
    white-space: nowrap;
  }

  &_action {
    background-color: transparent;
    border: none;
    color: $zd-blue;
    cursor: pointer;
    
    &:hover {
      color: $zd-blue-dark;
    }
  }
}
