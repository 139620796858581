@import '../../styles/main';

.ResultsHeader {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 200px;
  margin: 0 0 35px;
  
  .Button {
    margin: 0;
  }
}

.ResultsHeader_title {
  @include headerText();
  color: $black;
  justify-self: left;
  margin: 0 40px 0 0;
}

.ResultsHeader_actions {
  align-items: center;
  display: flex;
  justify-self: left;
}
