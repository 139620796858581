.fh-Zendesk {

  .fh-ZendeskLinkedItem {

    &.Button {
      margin: 0;
    }

    .BasicButton {
      display: block;

      .Button_button {
        border: none;
        line-height: 1.2;
        padding: 6px 2px 6px 0;
        text-align: left;

        // stylelint-disable-next-line selector-max-specificity
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
