@import '../../../../styles/main';

.flumeTrestle {
  
  .MembersSearchResultsItem {
    color: $grey2;
    cursor: pointer;
    
    &:hover {
      background-color: $fh-yellow-a16;
      color: $black;
    }
  }
}
