@import '../../../../styles/main';

.flumeTrestle {

  .PlatformSponsor {
    display: flex;
    margin: 0 0 40px;

    &_element {
      @include bodyText();
      margin: 0 30px 0 0;
    }

    &_label {
      color: $grey7;
      display: block;
    }
  }
}
