@import '../../../../styles/main';

.fh-ZendeskError {
  color: $zd-red-error;
  font-size: 14px;
  margin: 10px 0 0;
  overflow-wrap: break-word;
  position: relative;

  &_message {
    line-height: 1.3;
    padding: 0 0 0 25px;
    position: relative;

    &::before {
      background-color: $zd-red-error;
      border-radius: 50%;
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: 1px;
      width: 16px;
    }
  }

  &_refreshButton {
    display: block;
    margin: 0;
    text-align: right;
  }

  &_error {
    margin: 0 0 10px;
    padding: 0;
  }

  &_errors {
    border-top: 1px solid $zd-red-error;
    margin: 20px 0 0;
    padding: 20px 0 0;
  }
}
