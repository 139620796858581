.ResultsFilter {
  border-left: 10px solid #FFD31B;
  margin: 0 0 30px;
  padding: 12px 0 20px 25px;
  width: 690px;
  
  .SubsectionTitle {
    margin: 0 0 2px;
  }
}

.ResultsFilter_form {
  display: grid;
  grid-template-columns: 150px 230px 250px;
  
  .Input {
    margin: 0;
    min-height: 50px;
    min-width: 200px;
    width: 200px;
  }
  
  .Input:nth-of-type(1) {
    min-width: 120px;
    width: 120px;
  }

  .Dropdown {
    margin: 0;
    width: initial;
  }
}
