@import '../../../../../styles/main';

.fh-Zendesk {

  .fh-ZendeskHipaaMembers {
    margin: 0 0 25px 12px;

    &_overviewHeader {
      @include bodyText();
      margin: 0 0 5px;

      .BasicButton.u_linkButton {
        display: inline-block;
        margin: 0 0 0 5px;
      }
    }

    &_clearedMembers {
      margin: 0 0 30px 7px;

      .BasicButton.u_linkButton {
        display: block;
        margin: 0 0 5px;
      }
    }
  }
}
