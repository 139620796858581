@import '../../../../../styles/main';

.fh-Zendesk {

  .fh-ZendeskMemberClaims {
    margin: 0 20px;
    
    &_header {
      align-items: start;
      display: grid;
      grid-row-gap: 12px;
      grid-template-columns: max-content 100px 1fr;
      justify-content: start;
      
      .Dropdown {
        display: inline-block;
      }
      
      .fh-ClaimLookup {
        justify-self: right;
      }
    }

    &_title {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      margin: 0 20px 0 0;
      max-width: 500px;
    }
    
    &_columnLabels {
      column-gap: 2%;
      display: grid;
      grid-column: 1 / span 3;
      grid-template-columns: 9% 22% 11.5% 8% 1fr;
      margin: 0 0 10px;
    }

    &_columnLabelDate,
    &_columnLabelNumber,
    &_columnLabelCharge,
    &_columnLabelStatus,
    &_columnLabelProvider {
      display: inline-block;
      font-size: 13px;
      font-weight: 700;
      margin: 0 0 5px;
      text-align: center;
    }

    &_columnLabelDate,
    &_columnLabelProvider {
      text-align: left;
    }

    &_columnLabelCharge {
      text-align: right;
    }

    &_placeholder {
      @include zdPlaceholder();
      padding: 20px;
    }
    
    &_claimRows {
      position: relative;

      .fh-ZendeskMemberClaims_placeholder {
        background-color: $white-a94;
        height: 100%;
        left: -15px;
        margin: 0;
        position: absolute;
        top: 0;
        width: calc(100% + 30px);
        z-index: 2;
      }
    }
    
    &_notFound {
      margin: 0;
      padding: 10px 0;

      .fh-Zendesk_highlightText {
        padding: 5px 10px;
      }
    }
  }
}
