@import '../../../../styles/main';

.MemberSearchPage {
  @include listPage();

  .ThinkingDot {
    margin: 130px auto;
  }

  &_content {
    position: relative;
  }
}
