@import '../../styles/main';

.ResultsList {
  @include bodyText();
  border-collapse: collapse;
  width: 100%;
}

.ResultsList_headers {
  @include bodyText($weight: 700);
  border-bottom: 2px solid $black;
}

.ResultsList_headerItem {
  padding: 0 0 3px 8px;
  text-align: left;
}

.ResultsList_headerItemLabel {
  position: relative;

  &.u_iconArrowDown::after,
  &.u_iconArrowUp::after {
    opacity: .8;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%) scaleY(-1);
  }

  &.u_iconArrowDown::after {
    transform: translateY(-50%);
  }
}

.ResultsList_items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ResultsList_item {
  border-bottom: 2px solid transparent;
  border-top: 1px solid $grey5;
  cursor: default;
  letter-spacing: .4px;
  margin: 10px 0;
  opacity: .8;
  position: relative;
  transition: border-bottom-color .15s, border-top-color .15s;

  &:first-of-type {
    border-top-color: transparent;
    margin: 6px 0 10px;
  }

  &:last-of-type {
    margin: 10px 0 4px;
  }

  &:hover {
    @include bodyText($weight: 700);
    opacity: 1;
  }
}

.ResultsList_item___editable {
  cursor: pointer;
}

.ResultsList_itemCell {
  min-height: 30px;
  min-width: 30px;
  overflow-wrap: break-word;
  padding: 10px 30px 5px 8px;
  vertical-align: top;
}

.ResultsList_itemCellActions {
  opacity: .8;
  padding: 4px 0 5px 8px;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }
}

.ResultsList_itemCellData {
  display: block;
  margin: 0 0 2px;
}

.ResultsList_itemCellData:last-of-type {
  margin: 0 0 7px;
}

.ResultsList_itemDelete {
  @include iconDelete();
  opacity: .8;
}

.ResultsList_itemEdit {
  @include iconEdit();
}

.ResultsList_itemDelete,
.ResultsList_itemEdit {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: 40px;
  margin: 0 10px 0 0;
  position: relative;
  transition: background-color .2s;
  width: 40px;

  &:hover {
    background-color: $fh-yellow-light !important; // win specificity over row-level hover
    opacity: 1;
  }
}

.ResultsList_item:hover .ResultsList_itemCellActions {
  opacity: 1;
}

.ResultsList_item:hover .ResultsList_itemEdit {
  background-color: $fh-yellow-light;
}

.ResultsList_itemCellActions:hover .ResultsList_itemEdit {
  background-color: transparent;
}

.ResultsList_waiting {
  display: flex;
  height: 50vh;
  justify-content: center;
  text-align: center;

  .SpinnerScaleout {
    margin: 100px 0 0;
  }
}

.ResultsList_noResults {
  height: 50vh;
  margin: 25px 0 0 0;
}

.ResultsList_noResultsMessage {
  @include headerText();
}
