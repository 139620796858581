$disabled: .3;

// Flume Health brand colors
$fh-blue: #232C63;
$fh-blue-a30: rgba(35, 44, 99, .3);
$fh-blue-a80: rgba(35, 44, 99, .8);
$fh-green: #006472;
$fh-green-a60: #689AA2;

// to update
$fh-red-dark: #E73D55;
$fh-red-light: #FFE2E6;

// brand colors
$fh-blue-light: #C0F3FE;
$fh-blue-navy: #15127E;
$fh-blue-navy-a11: rgba(21, 18, 126, .11); // background svg, logo svg must have alpha value less than 1 to avoid optimization to hex
$fh-blue-navy-a14: rgba(21, 18, 126, .14); // background svg, logo svg must have alpha value less than 1 to avoid optimization to hex
$fh-blue-navy-a15: rgba(21, 18, 126, .15); // background svg, logo svg must have alpha value less than 1 to avoid optimization to hex
$fh-blue-navy-a30: rgba(21, 18, 126, .3);
$fh-blue-navy-a60: rgba(21, 18, 126, .6);
$fh-blue-navy-a99: rgba(21, 18, 126, .99); // background svg, logo svg must have alpha value less than 1 to avoid optimization to hex
$fh-green: #006472;
$fh-green-a30: rgba(0, 101, 114, .45); // background svg, logo svg
$fh-green-a99: rgba(0, 101, 114, .99); // background svg, logo svg
$fh-orange: #FF6E15;
$fh-orange-a15: rgba(255, 110, 21, .15);
$fh-pink: #FFD0F8;
$fh-red-salmon: #FF5D56;
$fh-red-salmon-a99: rgba(255, 93, 86, .99); // background svg
$fh-red-salmon-a80: rgba(255, 93, 86, .8);
$fh-red-salmon-a30: rgba(255, 93, 86, .3);
$fh-yellow: #FFD31B;
$fh-yellow-light: #FFEEAB;
$fh-yellow-a16: rgba(255, 211, 27, .16);
$fh-yellow-a22: rgba(255, 211, 27, .22);
$fh-yellow-a50: rgba(255, 211, 27, .5);

// standard colors
$bisque-a30: rgba(255, 228, 196, .3);
$black: #111;
$black-a80: rgba(0, 0, 0, .8);
$grey1: #CCC;
$grey2: #4C4C4C;
$grey3: #888;
$grey4: #EEE;
$grey5: #E8E8E8;
$grey6: #999;
$grey7: #6E6E6E;
$grey8: #F5F5F5;
$white: #FFF;
$white-a94: rgba(255, 255, 255, .94);

// usage types
$action-text: #FFF;
$background1: #FFF;
$accent1: $fh-red-salmon;

// state colors
$state-success: #228B22;

// form components
// $form-elem-bkg: transparent;
$form-button-color: #FFF;
$form-button-background: #689AA2;
$form-button-background-active: $fh-yellow;
$form-elem-selected: #0F0;
$form-control-primary: $black;
$form-control-background: transparent;
$form-control-background-selected: transparent;
$form-control-border: 2px solid $black;
$form-control-border-selected: 2px solid $form-elem-selected;
$form-control-icon-background: transparent;
$form-control-text: $black;
$form-elem-border: #111;

// errors
$error-from-elem-bkg: $fh-red-light;
$error-from-elem-border: $fh-red-dark;

// Zendesk
$zd-blue: #1F73B7;
$zd-blue-dark: #144A75;
$zd-blue-background: rgba(31, 115, 183, .08);
$zd-black-text: #2f3941;
$zd-black-text-80: #596167;
$zd-black-text-a80: rgba(47, 57, 65, .8);
$zd-grey-background: #F8F9F9;
$zd-grey-border: #E9EBED;
$zd-grey-border-light: #EDF7FF;
$zd-grey-border-med: #D8DCDE;
$zd-grey-border-med2: #C2C8CC;
$zd-grey-text: #68737D;
$zd-overlay-shadow: rgba(31, 115, 183, .35);
$zd-overlay-shadow2: rgba(4, 68, 77, .15);
$zd-yellow-extra-light: #FFF9E7;
$zd-red-error: #E34F32;
