// stylelint-disable scss/partial-no-import

@import 'colors';
@import 'text';

@mixin zdHeaderText() {
  font: 600 20px / 1 $zd-stack;
}

@mixin zdSecondaryHeaderText() {
  color: $zd-black-text;
  font: 600 16px / 1 $zd-stack;
}

@mixin zdLinkStyle() {
  color: $zd-blue;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: $zd-blue;
    text-decoration: underline;
  }
}

@mixin zdPlaceholder() {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 70px 0 0;
}

@mixin zdPrimaryButton() {
  background-color: $zd-blue;
  border-color: $zd-blue;
  color: $white;

  &:hover {
    background-color: $zd-blue-dark;
    border-color: $zd-blue-dark;
    color: $white;
  }
}

@mixin zdPrimaryButtonAlt() { // for dark backgrounds
  background-color: $zd-blue;
  border-color: $zd-blue;
  color: $white;

  &:hover {
    background-color: $white;
    border-color: $white;
    color: $zd-blue;
  }
}

@mixin zdSecondaryButton() {
  background-color: transparent;
  border: none;
  
  &:hover {
    background-color: transparent;
    color: $zd-blue;
    text-decoration: underline;
  }

  &:not([disabled]):hover {
    background-color: transparent;
  }
}

@mixin zdOverlay() {
  background-color: $white;
  border: 1px solid $zd-grey-border-med2;
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 $zd-overlay-shadow2;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
}
